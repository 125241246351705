import Link from 'next/link';
import React from 'react';

interface IHeaderLink {
    href: string;
    small?: boolean;
    target?: string;
}

const HeaderLink: React.FC<IHeaderLink> = ({ href = '', target, children, small }) => (
    <Link href={href}>
        <a
            className={`text-white font-serif px-10 tracking-wide ${
                small ? 'text-sm' : 'text-base'
            }`}
            href={href}
            target={target}
        >
            {children}
        </a>
    </Link>
);

export default HeaderLink;

HeaderLink.defaultProps = {
    small: false,
    target: '_self',
};
