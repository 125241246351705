import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAddressCard, faInfo } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

export interface ISidebarItem {
    id: string;
    label: string;
    href: string;
    target: string | null;
    icon: IconProp;
    permission?: string;
}

export const sidebarItems: ISidebarItem[] = [
    // {
    //     id: 'book',
    //     label: 'Book as Session',
    //     href: 'https://calendly.com/adrianstanek',
    //     target: 'calendly',
    //     icon: faCalendar,
    // },
    {
        id: 'linkedin',
        label: 'LinkedIn Profile',
        href: 'https://www.linkedin.com/in/adrianstanek',
        target: 'linkedin',
        icon: faLinkedin,
    },
    // {
    //     id: 'about',
    //     label: 'About me',
    //     href: '/page/about',
    //     target: null,
    //     icon: faAddressCard,
    // },
    {
        id: 'impressum',
        label: 'Imprint',
        href: '/page/impressum',
        target: null,
        icon: faInfo,
    },
    {
        id: 'datenschutz',
        label: 'Privacy Policy',
        href: '/page/privacy',
        target: null,
        icon: faInfo,
    },
];
