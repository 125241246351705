import React from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState } from 'recoil';
import HeaderLink from './HeaderLink';
import SidebarMobile from '../../SidebarMobile/SidebarMobile';
import sidebarRecoilState from '../../SidebarMobile/recoil/sidebar';

const Header: React.FC = () => {
    const [sidebar, setSidebar] = useRecoilState(sidebarRecoilState);

    return (
        <>
            <div className="relative flex h-16 w-full border-b border-primary bg-blue-900 bg-cover shadow-md">
                <div className="absolute top-0 left-0 block h-full w-full">
                    <Image
                        src="/images/asbg_flat.jpg"
                        layout="fill"
                        objectFit="cover"
                        quality={80}
                        alt="background"
                    />
                </div>
                <div className="absolute top-0 left-0 block h-full w-full bg-gray-900 bg-cover opacity-60" />
                <div className="container relative m-auto grid h-full grid-cols-12">
                    <div className="relative col-span-8 mt-3 ml-5 flex cursor-pointer flex-col md:col-span-4 md:ml-0">
                        <Link href="/" passHref>
                            {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                            <a href="/">
                                <Image
                                    src="/images/logoFullWhite.png"
                                    alt="header logo"
                                    layout="fill"
                                    objectFit="contain"
                                />
                            </a>
                        </Link>
                        <div className="absolute -bottom-1 left-36 hidden font-serif text-xs uppercase tracking-wide text-gray-400">
                            <span className="text-primary">Web Solutions&nbsp;</span> &{' '}
                            <span className="text-secondary">&nbsp;Self Development</span>&nbsp;Blog
                        </div>
                    </div>

                    <div className="col-span-4 md:col-span-8">
                        <div className="grid grid-cols-12">
                            <div className="col-span-12 mt-4 hidden flex-row justify-end p-5 md:flex">
                                {/* <HeaderLink */}
                                {/*    href="https://calendly.com/adrianstanek" */}
                                {/*    target="clandly" */}
                                {/* > */}
                                {/*    Book a Session */}
                                {/* </HeaderLink> */}
                                {/* <HeaderLink href="/page/about">About</HeaderLink> */}
                                <HeaderLink
                                    href="https://www.linkedin.com/in/adrianstanek"
                                    target="linkedin"
                                >
                                    <FontAwesomeIcon icon={faLinkedin} className="" />
                                </HeaderLink>
                            </div>

                            <div className="col-span-12 mt-1 flex flex-row justify-end p-3 md:col-span-1 md:hidden">
                                <button className="text-2xl text-white" type="button">
                                    <FontAwesomeIcon
                                        icon={faBars}
                                        onClick={() => setSidebar({ ...sidebar, show: true })}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SidebarMobile
                open={sidebar.show}
                setOpen={() => setSidebar({ ...sidebar, show: false })}
            />
        </>
    );
};

export default Header;
