import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { nanoid } from 'nanoid';
import { useRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { sidebarItems } from '../../data/navigationData';
import sidebarRecoilState from './recoil/sidebar';

interface ISidebarMobile {
    open: boolean;
    setOpen?: (boolean) => void;
}

const SidebarMobile: React.FC<ISidebarMobile> = (props) => {
    const { open, setOpen } = props;

    const [sidebar, setSidebar] = useRecoilState(sidebarRecoilState);

    const router = useRouter();

    return (
        <>
            <Transition.Root show={open} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-40 flex"
                    onClose={() => {
                        if (setOpen !== undefined) setOpen(false);
                    }}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div
                            id="sidebar-mobile"
                            className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4"
                        >
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        onClick={() => {
                                            if (setOpen !== undefined) setOpen(false);
                                        }}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="mt-5 h-0 flex-1 overflow-y-auto">
                                <nav className="flex flex-col space-y-1 px-2">
                                    {sidebarItems &&
                                        sidebarItems.map((item) => (
                                            <Link
                                                passHref
                                                id={`sidebar-${item.id}`}
                                                key={nanoid()}
                                                href={item.href}
                                                onClick={() => {
                                                    setSidebar({
                                                        ...sidebar,
                                                        current: item.id,
                                                    });
                                                    void router.push(item.href);
                                                }}
                                            >
                                                <a
                                                    target={item.target ?? ''}
                                                    href={item.href}
                                                    className={`flex h-10 w-full items-center gap-2 px-2 font-serif tracking-wider hover:bg-orange-200/30 ${
                                                        // eslint-disable-next-line no-nested-ternary
                                                        sidebar.current === item.id
                                                            ? 'text-primary-light'
                                                            : item.permission?.includes('admin')
                                                            ? 'text-orange-300'
                                                            : 'text-neutral-500'
                                                    }`}
                                                >
                                                    {item.icon && (
                                                        <FontAwesomeIcon
                                                            icon={item.icon}
                                                            className="w-12"
                                                        />
                                                    )}
                                                    <span>{item.label}</span>
                                                </a>
                                            </Link>
                                        ))}
                                </nav>
                            </div>

                            <div className="bg-primary-dark absolute bottom-0 left-0 flex w-full flex-col p-3 pb-8">
                                <span className="font-serif text-xs tracking-wider text-neutral-100" />
                            </div>
                        </div>
                    </Transition.Child>
                    <div className="w-14 flex-shrink-0" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

SidebarMobile.defaultProps = {
    setOpen: () => {},
};

export default SidebarMobile;
