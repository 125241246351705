import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faLinkedinIn, faMedium, faXingSquare } from '@fortawesome/free-brands-svg-icons';
import Link from 'next/link';
import dayjs from 'dayjs';

const navigation = {
    main: [
        { name: 'Contact', href: '/contact' },
        // { name: 'About', href: '/page/about' },
        { name: 'Privacy Policy', href: '/privacy' },
        { name: 'Imprint', href: '/imprint' },
        { name: 'webbar.dev', href: 'https://webbar.dev' },
    ],
    social: [
        {
            name: 'LinkedIn',
            href: 'https://de.linkedin.com/in/adrian-stanek-50ab01a8',
            icon: () => <FontAwesomeIcon icon={faLinkedinIn} />,
        },
        {
            name: 'Medium.com',
            href: 'https://medium.com/@adrianstanek',
            icon: () => <FontAwesomeIcon icon={faMedium} />,
        },
        // {
        //     name: 'GitHub',
        //     href: 'https://github.com/adrianstanek',
        //     icon: () => <FontAwesomeIcon icon={faGithubSquare} />,
        // },
        {
            name: 'Xing',
            href: 'https://www.xing.com/profile/Adrian_Stanek',
            icon: () => <FontAwesomeIcon icon={faXingSquare} />,
        },
        {
            name: 'webbar.dev',
            href: 'https://webbar.dev',
            iconLarge: (
                <div className="relative flex  h-10 w-10">
                    <img src="/logos/webbar/wb_icon_light_512.png" alt="webbar.dev" />
                </div>
            ),
        },
    ],
};

const Footer: React.FC = () => (
    <>
        <footer className="relative mt-10 w-full bg-white">
            <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
                <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                    {navigation.main.map((item) => (
                        <div key={item.name} className="px-5 py-2">
                            <Link href={item.href}>
                                <a
                                    className="font-serif text-base text-gray-500 hover:text-gray-900"
                                    href={item.href}
                                    target={item.name}
                                >
                                    {item.name}
                                </a>
                            </Link>
                        </div>
                    ))}
                </nav>
                <div className="mt-8 flex justify-center space-x-6">
                    {navigation.social.map((item) => (
                        <a
                            key={item.name}
                            href={item.href}
                            className="text-gray-400 hover:text-gray-500"
                        >
                            <span className="sr-only">{item.name}</span>
                            <span className="text-4xl">
                                {item.iconLarge && item.iconLarge}
                                {item.icon && <item.icon aria-hidden="true" />}
                            </span>
                        </a>
                    ))}
                </div>
                <div className="mt-8 text-center text-base text-gray-400">
                    &copy; {dayjs().format('YYYY')} Adrian Stanek
                </div>

                <Link href="/newsletter/unsubscribe" passHref>
                    <button
                        type="button"
                        className="relative mt-3 flex w-full justify-center text-center text-sm text-gray-400 "
                    >
                        Newsletter unsubscribe
                    </button>
                </Link>
            </div>
        </footer>
    </>
);

export default Footer;
