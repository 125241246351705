import { atom } from 'recoil';
import { nanoid } from 'nanoid';

interface ISidebarAtom {
    current: string;
    show: boolean;
}

const sidebarRecoilState = atom<ISidebarAtom>({
    key: `sidebar-state/${nanoid()}`,
    default: {
        show: false,
        current: 'cards',
    },
});

export default sidebarRecoilState;
